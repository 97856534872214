// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.curved {
    border-top-left-radius: 50% 100%;
    border-top-right-radius: 50% 100%;
    position: absolute;
    bottom: 0;
    z-index: 1;
    width: 100%;
    background: #fff;
    height: 10%;
  }
`, "",{"version":3,"sources":["webpack://./src/oth/shape.css"],"names":[],"mappings":"AAAA;IACI,gCAAgC;IAChC,iCAAiC;IACjC,kBAAkB;IAClB,SAAS;IACT,UAAU;IACV,WAAW;IACX,gBAAgB;IAChB,WAAW;EACb","sourcesContent":[".curved {\n    border-top-left-radius: 50% 100%;\n    border-top-right-radius: 50% 100%;\n    position: absolute;\n    bottom: 0;\n    z-index: 1;\n    width: 100%;\n    background: #fff;\n    height: 10%;\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
